import {Drawer} from "@mui/material";
import {destroyModal, useModal} from "../../utils/modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark, faClock} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {TranslatedAttribute} from "../../../../utils";
import AllergenIcon from "../AllergenIcon";
import ProductForm from "../Product/ProductForm";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const ProductDetailModal = () => {
  const modal = useModal();
  const {locale, showProductOptions} = useSelector(state => state.app);
  const {t} = useTranslation();
  return (
    <>
      {
        <Drawer
          PaperProps={{
            sx: {
              backgroundColor: "transparent",
              boxShadow: 'none'
            }
          }}
          anchor={'bottom'}
          open={true}
          onClose={destroyModal}
        >

          <div className={'w-full max-w-[420px] mx-auto bg-[#fff] h-full rounded-t-[30px] p-4 relative'}>
            <FontAwesomeIcon icon={faCircleXmark} size={'2x'}
                             className={'text-[#152553] w-[40px] h-[40px] absolute'}
                             style={{right: 5, top: 5}}
                             onClick={destroyModal}/>
            <div
              className={'overflow-scroll no-scrollbar max-h-[540px] flex flex-col justify-start items-center mt-10 relative'}>
              <div className={'flex justify-center'}>
                <img src={require('../../assets/img/bottom-pattern.png')} alt="" width={160}
                     style={{rotate: '180deg'}}/>
              </div>

              <div className={'flex flex-row my-2'}>
                {
                  (modal.data.ingredient_tags).map(tag => {
                    return (
                      <AllergenIcon tag={tag}/>
                    )
                  })
                }
              </div>

              <div className={'w-full'}>

                <div className={'flex flex-col'}>
                  <span className={'font-bold text-xl'}>{TranslatedAttribute(locale, modal.data, 'name')}</span>
                  <span
                    className={'font-normal text-[18px] mb-2'}>{TranslatedAttribute(locale, modal?.data, 'description')}</span>
                </div>
                {
                  showProductOptions &&
                  <ProductForm item={modal.data}/>
                }

                <div>
                  {
                    modal.data.preparation_time && (
                      <div className={'text-center flex items-center justify-center bg-[#f8f8f8] rounded-xl mt-4'}>
                        <span className={'m-2 text-sm'}>{modal.data.preparation_time} {t('general.minute')} </span>
                        <FontAwesomeIcon icon={faClock} className={'text-[#808080]'}/>
                      </div>
                    )
                  }

                </div>

              </div>

              {
                modal.data.note && (
                  <div className={'bg-blue-100 px-4 py-1 rounded-xl text-md mt-3'}>
                    {TranslatedAttribute(locale, modal.data, 'note')}
                  </div>
                )
              }
            </div>
          </div>
        </Drawer>
      }
    </>
  )
}

export default ProductDetailModal;