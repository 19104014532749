import React, {useEffect, useState} from "react";
import Loader from "../../components/Loader";
import MainCategories from "../../components/Categories/MainCategories";
import Categories from "../../components/Categories/Categories";
import Header from "../../components/Header/Header";
import {useSelector} from "react-redux";
import {useGetAllProductsQuery} from "../../../../storage/api";
import BasketButton from "../../components/BasketButton";
import {AnimatePresence, motion as m, useAnimationControls} from "framer-motion";
import WaiterCallButton from "../../../../components/WaiterCallButton";

const Menu = () => {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const {loading} = useSelector(state => state.loader);
  const {isOrderAvailable, menuId, menuInfo, tableId} = useSelector(state => state.app)
  const {data, isLoading} = useGetAllProductsQuery(menuId);
  const controller = useAnimationControls();

  useEffect(() => {
    if (!isLoading) {
      setItems(data);
      setSelectedItem(data[0]);
      controller.start({opacity: 1}).then()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return (

    <div className={'bg-primary text-white pb-[75px]'}>
      <m.div
        key={'menu'}
        initial={{opacity: 0}}
        animate={controller}
        transition={{duration: 0.3, ease: "easeOut"}}
        exit={{opacity: 0}}>
        {
          items && items.length > 0 && (
            <div>
              {
                tableId && menuInfo.can_call_waitstaff &&
                <WaiterCallButton/>
              }
              <div className={'mx-[30px]'}>
                <Header isShowHomeButton={true}/>
              </div>
              <MainCategories items={items} selectedItem={selectedItem} setSelectedItem={setSelectedItem}/>
              <div className={'mx-[30px] mt-4'}>
                <AnimatePresence mode={'wait'}>
                  <m.div
                    key={selectedItem.id}
                    initial={{height: '0'}}
                    transition={{duration: 1}}
                    animate={{height: 'auto'}}
                    exit={{height: '0'}}
                    className={'overflow-hidden h-0'}>
                    <p className={'text-[20pt]'}>
                      {selectedItem.name}
                    </p>
                    <Categories items={selectedItem.sub_items}/>
                  </m.div>
                </AnimatePresence>
              </div>
              {
                isOrderAvailable ? <BasketButton/> : <></>
              }
            </div>
          )
        }
      </m.div>

      <AnimatePresence>
        {
          (items == null || items.length === 0) && (
            <m.div
              key={'loading'}
              initial={{opacity: 1}}
              transition={{duration: 0.3, ease: "easeOut"}}
              exit={{opacity: 0}}>
              <Loader/>
            </m.div>
          )
        }
      </AnimatePresence>
      {
        tableId && menuInfo.can_call_waitstaff &&
        <div className={'pt-[55px]'}/>
      }
      {
        loading ? <Loader/> : null
      }
    </div>
  )
}

export default Menu;