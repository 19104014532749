import {Drawer} from "@mui/material";
import {useModal} from "../../utils/modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {TranslatedAttribute} from "../../../../utils";
import AllergenIcon from "../../../ImageDefault/components/AllergenIcon";
import ProductForm from "../Product/ProductForm";

const ProductDetailModal = () => {

  const modal = useModal();
  const {locale, showProductOptions} = useSelector(state => state.app);
  const [open, setOpen] = useState(false);
  const close = () => {
    setOpen(false);
  }
  useEffect(() => {
    setOpen(true)
  }, []);
  return (
    <>
      {
        <Drawer
          PaperProps={{
            sx: {
              backgroundColor: "transparent",
              boxShadow: 'none'
            }
          }}
          anchor={'bottom'}
          open={open}
          onOpen={() => {
          }}
          onClose={close}
        >

          <div className={'w-full max-w-[420px] mx-auto bg-[#fff] h-full rounded-t-[30px] p-4 relative'}>
            <FontAwesomeIcon icon={faCircleXmark} size={'2x'}
                             className={'no-image-text-secondary w-[40px] h-[40px] absolute'} style={{right: 5, top: 5}}
                             onClick={close}/>
            <div
              className={'overflow-scroll no-scrollbar max-h-[540px] flex flex-col justify-start items-center mt-10 relative'}>
              <div className={'flex flex-row my-2'}>
                {
                  (TranslatedAttribute(locale, modal.data, 'ingredient_tags')).map((tag, idx) => {
                    return (
                      <AllergenIcon key={`${idx}-${tag}`} tag={tag}/>
                    )
                  })
                }
              </div>

              <div className={'flex flex-row my-2'}>
                {TranslatedAttribute(locale, modal.data, 'description')}
              </div>
              {
                showProductOptions && <ProductForm item={modal.data}/>
              }
              {
                modal.data.note ? (
                    <div className={'bg-blue-100 px-4 py-1 rounded-xl text-xs mt-3'}>
                      {TranslatedAttribute(locale, modal.data, 'note')}
                    </div>
                  ) :
                  (
                    <></>
                  )
              }

            </div>
          </div>
        </Drawer>
      }
    </>
  )
}

export default ProductDetailModal;